import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import Axios from "axios"
import "../login.css"

const ResetPassword = ({ token }) => {
  const [password, setPassword] = useState(""),
    [repeatPassword, setRepeatPassword] = useState(""),
    [loading, setLoading] = useState(false),
    [errorMessage, setErrorMessage] = useState(""),
    [isValidToken, setIsValidToken] = useState(true)

  const verifyToken = () => {
    Axios.get(`${process.env.GATSBY_API_URL}/reset-password/verify-token/${token}`)
      .then(function (response) {
        setIsValidToken(true)
      })
      .catch(function (error) {
        if (error.response.status === 400) {
          setIsValidToken(false)
          alert(error.response.data.message)
          navigate("/login")
        }
      })
  }

  const handleResetPassword = event => {
    event.preventDefault()

    if (!password || !repeatPassword) {
      return false
    }

    if (password !== repeatPassword) {
      setErrorMessage("Password does not match with repeat password.")
      return false
    }

    setLoading(true)

    Axios.post(`${process.env.GATSBY_API_URL}/reset-password/reset/${token}`, {
      password: password,
      repeatPassword: repeatPassword,
    })
      .then(function (response) {
        navigate("/login")
      })
      .catch(function (error) {
        if (error.response.status === 400) {
          setErrorMessage(error.response.data.message)
        }
      })
  }

  useEffect(() => {
    verifyToken()
  }, [])

  return (
    <React.Fragment>
      <section className="loginSection">
        <div className="loginContainer">
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <form
                onSubmit={event => {
                  handleResetPassword(event)
                }}
                id="req-reset-pwd"
              >
                <div className="form-group mb-3">
                  <h2 className="text-center">Password Reset</h2>
                </div>
                <div className="input-group mb-3">
                  <span
                    className="input-group-text"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Password"
                  >
                    <i className="bi bi-lock-fill" />
                  </span>
                  <input
                    id="password"
                    className="form-control formControlInput"
                    type="password"
                    tabIndex="1"
                    placeholder="New Password"
                    required
                    onChange={event => {
                      setPassword(event.target.value)
                    }}
                  />
                </div>
                <div className="input-group mb-3">
                  <span
                    className="input-group-text"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Repeat Password"
                  >
                    <i className="bi bi-lock-fill" />
                  </span>
                  <input
                    id="repeatPassword"
                    className="form-control formControlInput"
                    type="password"
                    tabIndex="1"
                    placeholder="Repeat Password"
                    required
                    onChange={event => {
                      setRepeatPassword(event.target.value)
                    }}
                  />
                </div>
                {errorMessage && <div className="error mb-3">{errorMessage}</div>}
                <div className="mb-3">
                  <button
                    type="submit"
                    disabled={loading}
                    id="loginModalButton"
                    className="btn btn-primary w-100"
                  >
                    <i className="bi bi-arrow-right-circle" /> Reset Password{" "}
                    {loading && <i className="spinner-border spinner-border-sm" />}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default ResetPassword
