import * as React from "react"

import Seo from "../../../components/seo"
import ReduxWrapper from "../../../redux/reduxWrapper"
import ResetPassword from "../../../components/ResetPassword/resetPassword"

const ResetPage = props => {
  return (
    <React.Fragment>
      <Seo title="Reset Password" />
      <ResetPassword token={props.params.token} />
    </React.Fragment>
  )
}

const WrappedPage = props => <ReduxWrapper element={<ResetPage {...props} />} />
export default WrappedPage
